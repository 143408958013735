.teambg {
  background-color: #055273;
  width: 218px !important;
  height: 294px !important;
  border-radius: 5px;
}
.teambg :hover{
  transform: scale(1.1);
  transition: .3s;
}
.teamImg {
  display: contents;
  justify-content: center;
  text-align: center;
  z-index: 100000;
  top: -13% !important;
}
.teamImg img {
  padding-top: 12px;
  padding-left: 15px;
  transition: width 2s, height 4s;
}
.teamImg img:hover {
  /* width: ; */
  transform: scale(0.9);
  transition: transform 0.3s;
}
.teamImg h6 {
  padding-top: 14px;
  letter-spacing: 2px;
}

.waviy {
  text-align: center;
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  font-size: 60px;
}
.waviy span {
  font-family: "Alfa Slab One", "Times New Roman", Times, cursive;
  position: relative;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(0.1s * var(--i));
}
@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

.waviy2 {
  position: relative;
  text-align: center;
  letter-spacing: 3px;
}
.waviy2 span {
  position: relative;
  display: inline-block;
  font-family: "Alfa Slab One", "Times New Roman", Times, cursive;
  font-size: 3rem;

  color: #fff;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}



.teamName {
  text-align: center;
  font-weight: 800;
}

@media screen and (max-width: 600px) {
  .abcTeam {
    padding: 15px;
    display: inline-grid;
    justify-content: center;
  }
  .waviy2 span,
  .waviy span {
    font-size: 1.8rem;
  }
}
