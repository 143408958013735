.landingPageContainer1 {
  padding: 0rem 4.5rem 0rem 7rem;
  font-family: Bangers;
  height: 100%;
}
.video1{
/* width: 100vw; */
}

.explore h1 {
  font-size: 3.5rem;
  padding-left: 3rem;
  padding-top: 12rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 900 !important;
}

.the {
  font-size: 6rem;
  margin-top: 1rem;
}
.digitalWorld {
  font-size: 9rem;
}
.world {
  color: yellow;
  font-size: 5rem;
}
.with {
  line-height: 1px;
  font-size: 4.5rem;
  padding-right: 00.5rem;
}
.polarverse {
  font-family: Bangers;
  font-size: 9rem;
  color: #310f6e;
  -webkit-text-stroke: 2px #fcfe0e;
  /* text-shadow: 5px 0 0 #FCFE0E; */
}
/* 2nd container  */
.landingPageContainer2 {

  font-weight: bold !important;
  font-style: normal;
}

.landingPageTextHeading {
  font-size: 5rem;
  text-align: center;
  letter-spacing: 7px;
  font-weight: 900;
}
.musicCol{
  display: block;
}
.music{
  margin-top: 2rem;
  color: white;
  font-weight: bold;
  padding: 6px 18px 6px 18px;
  border-radius: 12px;
  background-color: #00cbf5;
}

.landingPageText1 {
  padding: 1rem 5rem 1rem 5rem;
  font-size: 1.1rem;
  text-align: justify;
  font-weight: normal !important;

}
.exploreGameBtn {
  margin-top: 3.75rem;
  width: 17.638rem;
  height: 4.688rem;
  background: linear-gradient(
    178.56deg,
    rgba(0, 203, 245, 0.76) 1.22%,
    rgba(11, 20, 60, 0.84) 97.25%
  );
  border-radius: 1.43rem;
  color: white;
  font-weight: bold;
}


.landingPageContainer3 {
  font-family: "Roboto";
  padding-top: 13rem;
  margin-bottom: 9rem;
}
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #02ffdd 0%,
    #7b00ff 29%,
    #e5ff00 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* CSS */
.button-85 {
  padding: 0.6em 2em;
  font-weight: 900;
letter-spacing: 1px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;

}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.landingPageContainer3List {
  padding-top: 1rem;
  line-height: 3rem;
  font-weight: bold;
}
.landingPageContainer3List li:hover{
color: violet;
margin-left: 12px;


}
.landingPageContainer3Heading {
  font-weight: bold;
}
.landingPage3Gif {
  display: flex;
  justify-content: center;
}
.landingPageCol3 {
  padding-left: 3rem !important;
}


.playNowBtn {
  background: linear-gradient(90deg, #7ddcf7 0.3%, #614eba 100%);
  border-radius: 10px;
  width: 205px;
  height: 37px;
  text-decoration: none;
  justify-content: center;
  padding-top: 6px;
  color: white;
  font-weight: bold;
}
.borderin3 {
  border-radius: 6px;
  padding: 1rem;
  background-color: black;
}

/* container4  */
.container4 {
  padding: 12rem 1rem 12rem 1rem;
  align-items: center;
}
.textShadow{
  font-size: 22px;
  text-shadow: 1px 3px #492fa5;
}
.container4Sec {
  border-radius: 12px;
  border: 2px solid #3f98b3;
  padding: 1rem 1.8rem 1rem 1.8rem;
  width: 83.666667% !important;
}
.container4img {
  width: 19rem;
}
.container4btn {
  margin-top: 1rem;
  color: white;
  width: 148px;
  height: 31px;
  background: linear-gradient(
    178.56deg,
    rgba(0, 203, 245, 0.76) 1.22%,
    rgba(11, 20, 60, 0.84) 97.25%
  );
  border-radius: 47px;
  text-decoration: none;
}

/* landingPageContainer5  */

.landingPageContainer5 {
  /* padding-top: 1rem; */
  /* padding-top: 3rem;
  padding-bottom: 3rem; */
}

.landingPageContainer5Heading {
  padding: 2rem;
  background: linear-gradient(180deg, #302284 0%, #140638 100%);
}

.landingPageContainer5Row {
  padding: 2rem 0rem 4rem 0rem;
}

.musicTable {
  color: #ffffff !important;
  background-color: rgba(37, 37, 37, 0.85);
  border-radius: 12px;
  border-bottom: none;
}

/* landingPageContainer6Row  */
.landingPageContainer6 {
  padding: 3rem 0rem 4rem 3rem;
}
.landingPage6Heading{
  font-family: "Red Rose" !important;
  font-size: 72px;
}
.landingPageContainer6 h1 {
  padding: 7.5rem 6rem 2.5rem 7rem;
}
.landingPageContainer6 p {
  padding: 0rem 6rem 3rem 7rem;
  text-align: justify;
  font-size: 1.5rem;
}
.landingPageContainer6 button {
  font-family: Roboto !important;
  width: 12rem;
  height: 2.8rem;
  background: linear-gradient(
    178.56deg,
    rgba(0, 203, 245, 0.76) 1.22%,
    rgba(11, 20, 60, 0.84) 97.25%
  );
  border-radius: 12px;
  color: white;
}

/* .landingpageContainer7 */

.landingpageContainer7 {
  padding: 3rem 0rem 0rem 0rem;
}
.landingPage7Heading {
  padding-right: 2rem;
  padding-bottom: 3rem;
  text-align: end;
  font-size: 3rem;
  color: rgb(0, 0, 0);

  font-weight: bold;
  font-family: "Press Start 2P", cursive;
}
.explorationBtn {
  width: 206px;
  height: 46px;
  background: linear-gradient(178.56deg, #00cbf5 1.22%, #0b143c 97.25%);
  border-radius: 47px;
  color: white;
}

.img7 {
  padding-bottom: 12rem;
}
/* landingPageContainer8  */

.landingPageContainer8 {
  padding-top: 1rem;
  margin-top: 1rem;
}
.landingPageContainer9{
  padding-bottom: 0rem;
}

/* //media queries */

* {
  margin: 0;
  padding: 0;
}

/* Default Design */
.gfg-div {
  /* To make all elements center */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Default Styling */
  margin: 20px auto;
  padding: 30px;
  font-size: 30px;
  width: 300px;
  height: 300px;
  background-color: darkseagreen;
  color: black;
}


/* / for large desktop  */
@media screen and (min-width: 3840px) {
  .container4img{
    width: 25rem;
  }
  .landingPageContainer9{
    padding-bottom: 7rem;
  }
}
@media screen and (min-width: 1920px) {
  .explore h1{
    margin-top: 6rem;
    font-size: 6rem;
  }
  .world{
    font-size: 7rem;
  }
  .landingPageTextHeading{
    font-size: 3rem !important;
  }
  .landingPageText1{
    font-size: 2rem !important;
    
  }
}

/* For Desktop View */

@media screen and (min-width: 1024px) {
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .landingPageContainer1 {
    padding: 7rem 3rem 0rem 3rem;
    font-family: Bangers;
    height: 100%;
  }

  .explore  h1{
    padding-top: 10%;
    font-size: 2rem;
    text-align: center;
  }
  .the {
    font-size: 5rem;
    margin-top: 1rem;
  }
  .digitalWorld {
    font-size: 7rem;
  }
  .world {
    color: yellow;
    font-size: 3rem;
  }
  .with {
    line-height: 1rem;
    font-size: 7rem;
    padding-right: 00.5rem;
  }
  .polarverse {
    font-family: Bangers;
    font-size: 7rem;
    color: #310f6e;
    -webkit-text-stroke: 2px #fcfe0e;
    /* text-shadow: 5px 0 0 #FCFE0E; */
  }
  /* container4  */
  .container4{
    padding-left: 4rem !important;
  }
  .container4img{
    width: 5rem;
  }

.landingPageTextHeading{
  font-size: 3rem !important;
}
.landingPageText1{
  font-size: 1rem !important;
  padding-top: 3rem;
}

  /* container6  */
  .landingPageContainer6 p {
    text-align: center;
    font-size: 1.4rem !important;

  }
  .landingPageContainer6 h1{
    font-size: 2rem;
  }
  .landingPage7Heading{
    text-align: center;
  }
  /* landingPage7Heading  */
  .landingPage7Heading{
    padding: 60% 0rem 0rem 1rem;
  }
  .landingPageContainer9{
    padding-bottom: 9rem !important;
  }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) {
  .landingPageContainer1 {
    padding: 23vh 0.2rem 0rem 0.7rem !important;
    font-family: Bangers;
    height: 100%;
    line-height: 5em;
    text-align: center;
  }
  .explore h1{
    padding-top: 1vh;
    padding-left: 0rem;
    font-size: 1rem;
    text-align: center;
  }
  .the {
    font-size: 2.3rem;
    margin-top: 1rem;
  }
  .digitalWorld {
    line-height: 1rem;
    font-size: 3.3rem;
  }
  .world {
    color: yellow;
    font-size: 3.3rem;
  }
  .with {
    line-height: 3rem;
    font-size: 2.9rem;
    padding-right: 00.5rem;
  }
  .polarverse {
    font-family: Bangers;
    font-size: 1rem !important;
    color: #310f6e;
    -webkit-text-stroke: 2px #fcfe0e;
    /* text-shadow: 5px 0 0 #FCFE0E; */
  }

  .landingPageTextHeading{
    font-size: 2.5rem;
  }
  /* landingpage3  */
  .landingPageContainer3 {
    margin-bottom: 2rem;
  }
  .landingPageContainer3Heading {
    padding-top: 3rem;
  }

  /* .container4 */

  .container4Col{
    margin-top: 1rem !important; 
  }
  .container4Sec{
    border-radius: 1rem;
  }
  .landingPageContainer6{
    padding: 1rem 1rem 1rem 1rem;
  }
  
  /* .landingPage7 */
  .landingpageContainer7 {
    padding: 4rem 0rem 1rem 0rem;
  }
  .landingPage7Heading {
    font-size: 1.3rem;
    padding-left: 0.5rem;
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 35vh !important;
  }
  .img-wrapper7 {
    padding-top: 10rem;
  }
  .landingPageContainer9{
    padding-bottom: 7rem;
  }
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  /* .container1  */
  .landingPageContainer1 {
    padding: 6rem 2rem 0rem 3rem;
  }
  .explore {
    font-size: 2rem;
    
  }
  .the {
    font-size: 2.1rem;
  }
  .digitalWorld {
    font-size: 2.9rem;
  }
  .world {
    line-height: 3rem;
    font-size: 1.9rem;
  }
  .with {
    font-size: 2.9rem;
  }
  .polarverse {
    font-size: 2.9rem;
  }
  .landingPageContainer6{
    padding: 1rem 1rem 1rem 1rem;
  }
  /* container7  */
  .landingPage7Heading {
    font-size: 6vw;
    text-align: center;
    padding: 28vh 0px 2rem 0px;
  }
  .landingPageContainer9{
    padding-bottom: 7rem;
  }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  /* .landingPageContainer1 */
  .landingPageTextHeading {
    font-size: 1.7rem;
    line-height: 2rem;
  }
  .landingPageText1 {
    font-size: 0.5rem;
  }
  .exploreGameBtn {
    margin-top: 3.75rem;
    width: 12.638rem;
    height: 2.688rem;
    background: linear-gradient(
      178.56deg,
      rgba(0, 203, 245, 0.76) 1.22%,
      rgba(11, 20, 60, 0.84) 97.25%
    );
    border-radius: 1.43rem;
    color: white;
    font-weight: bold;
  }
  /* //landingPageContainer3 */
  .landingPageContainer3 {
    padding-top: 4rem;
  }
  /* landingpagecontainer5  */
  .landingPageContainer5Row {
    padding: 1rem 0rem 1rem 0rem;
    height: auto !important;
  }

  /* container6  */

  .landingPage6Heading {
    font-size: 1rem;
    padding: 1rem 0rem 1rem 0rem !important;
    text-align: center;
  }
  .landingPageContainer6 p {
    padding: 1.3rem;
    font-size: 0.7rem;
  }
  .landingPageContainer9{
    padding-bottom: 11rem;
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
}
