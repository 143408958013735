#bg-video{
    /* opacity: 0.7; */
    width: 100vw;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    
}
video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: 94%   !important;
}
#bg-video { position:relative; }

#bg-video video {
    position:relative;
    z-index:0;
    overflow: hidden;
}
.videoText{
    position:absolute;
    top:0;
    left:0;
    z-index:1;
}
div.relative {
    position: relative;
    width: 100%;
    height: 100%;
  } 
  
  div.absolute {
    position: absolute;
    top: 0vh;
    /* left: 40px; */
    width: 100vw;
    font-size: 3rem;
    font-weight: bold;
  }






  
  
    .video-container {
  z-index: -100;
  width:100%;
  height:100%;
  position:relative;
  top:0;
  left:0;
  }
  
  #video-bg{
    width:100%;
   
  }
  
  .portfolio-section{
    margin-top:50%;
  }
  
  
    