.MusicRow {
  padding-left: 4rem;
  padding-top: 1rem !important;
}
.musicHead {
  padding-top: 5rem;
  font-family: Patua One;
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 4rem;
}
.musicInfo {
  padding-top: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.MuicNftInfo{
  text-align: left;
  padding: 12px 20px 12px 20px;
  font-family: Poppins;
  font-size: 18px;

}
.musicBtnn {
  width: 211px;
  height: 58px;
  background: rgba(255, 255, 1, 0.95);
  border-radius: 6px;
  text-transform: uppercase;
  margin-left: 9rem;
}

.musicHead2 {
  font-family: Patua One;
  font-style: normal;
  font-weight: normal;
  font-size: 59px;
  line-height: 4rem;
}

.ull {
  width: max-content;
  height: 7px;
  border-bottom: 10px solid #f3df04;
}

/* 2nd container  */
.music2Container {
  /* padding: 6rem 6rem 0rem 6rem; */
}
.music2Container h3 {
  padding-bottom: 3rem;
  font-family: Patua One;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 41px;
}
.ul3 {
  border-bottom: 2px solid yellow;
}
.musicPoints {
  padding: 1rem 8rem 1rem 8rem !important;
}
.polarverseMusicPoint {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  padding-top: 1.6rem;
}

/* joinCommunity  */
.joinCommunity {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.joinCommunitytext {
  font-family: Patua One;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 59px;

  color: #25004a;
}

.joinCommunityBtn {
  width: 263px;
  height: 50px;
  background: rgba(255, 255, 1, 0.95);
  border-radius: 7px;
  margin-left: 6rem;
  /* border: none; */
}
/* talent section  */
.talentText {
  padding: 0px 4rem 0px 4rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 51px;
  text-align: center;

  color: #ffffff;
}

.talentImg {
  margin-top: 4rem !important;
}
.talent2img {
  padding-top: 2rem !important;
}
.talent3img {
  padding-top: 5rem;
  padding-left: 6rem !important;
}
.talent5 {
  text-align: end;
  padding-top: 2.7rem;
}
.talentLastText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
text-align: justify;
  color: #ffffff;
  margin-top: 3rem;
}
.getStarted{
  width: max-content;
color: yellow;
font-size: 24px;  
font-weight: bold;
}
.talent6Img {
  padding-left: 4rem !important;
}
.talentTextNew{
font-family: Poppins;
font-size: 18px;
font-weight: 500;
}

@media only screen and (min-width: 400px) {
  /* For tablets: */
}
@media only screen and (min-width: 500px) {
  /* For tablets: */
 
  .musicHead{
    font-size: 40px;
    line-height: 3rem;
  }
  .musicHead2{
    font-size: 40px;
  }
  .MusicRow{
    padding-top: 5rem;
  }
  .musicHeadphoneImg{
    width: 290px;
    height: 290px !important;
    margin-top: 5rem;
  }
  .musicPoints{
    padding: 1px 1px 1px 1px !important;
  }

}
@media only screen and (min-width: 870px) {
  /* For desktop: */
  
}
@media only screen and (min-width: 1280px) {
  /* For desktop: */
}
@media only screen and (min-width: 1920px) {
  /* For desktop: */
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}

@media screen and (max-width: 500px) and (min-width: 350px) {
  .MusicRow {
    padding :2rem 0px 0px 0px ;
  }
  .musicHead {
    padding-top: 1rem;
    font-family: Patua One;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2rem;
  }
  .musicHead2 {
    line-height: 2rem !important;
    font-size: 1.6rem;
  }
  .musicBtnn {
    text-align: center;
    margin-left: 0px;
  }
  .musicInfo {
    padding-top: 1rem;
  }
  .musicBtnn {
    width: 150px;
    height: 38px;
  }
  .musicHeadphoneImg{
    width: 191px;
  }
  /* 2nd container  */
  .music2Container{
    padding-top: 1rem;
  }
  .music2Container p {
    font-size: 17px;
  }
  .musicPic{
    width: 160px;
  }
  .polarverseMusicPoint{
    padding-top: 1rem;
  }
  .musicPoints{
    padding: 10px 1px 1px 1px !important;
  }
  .joinCommunitytext{
    padding-top: 12px;
    line-height: 24px;
    text-align: center;
    font-size: 1.4rem !important;
  }
.joinCommunityBtn{
  width: 140px;
  margin-left: 0rem;
  text-align: center;
}
.joinCommunityDivBtn{
  text-align: center;
  padding-bottom: 1rem;
}
.talentText{
  line-height: 20px;
  padding: 1px 12px 1px 12px;
}
}

@media screen and (max-width: 350px) and (min-width: 200px) {
  .MusicRow {
    padding: 0px;
  }
  .musicHead {
    padding-top: 1rem;
    font-family: Patua One;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2rem;
  }
  .musicHead2 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .musicBtnn {
    text-align: center;
    margin-left: 0px;
  }
  .musicInfo {
    padding-top: 1rem;
  }
  .musicBtnn {
    width: 150px;
    height: 38px;
  }
  .musicHeadphoneImg{
    width: 161px;
  }
  /* 2nd container  */
  .music2Container{
    padding-top: 1rem;
  }
  .music2Container p {
    font-size: 17px;
  }
  .musicPic{
    width: 160px;
  }
  .polarverseMusicPoint{
    padding-top: 1rem;
  }
  .musicPoints{
    padding: 10px 1px 1px 1px !important;
  }
  .joinCommunitytext{
    padding-top: 12px;
    line-height: 24px;
    text-align: center;
    font-size: 1.4rem !important;
  }
.joinCommunityBtn{
  width: 140px;
  margin-left: 0rem;
  text-align: center;
}
.joinCommunityDivBtn{
  text-align: center;
  padding-bottom: 1rem;
}
.talentText{
  line-height: 20px;
  padding: 1px 12px 1px 12px;
}
}

/* specifi phone  */
@media only screen and (device-width: 540px) and (device-height: 720px) {

.MusicRow{
  padding-top: 0rem;
}
.musicHead{
  padding-top: 0rem;

}
.musicHeadphoneImg{
  width: 170px;
  height: auto !important;
}
.joinCommunityBtn{
  margin-bottom: 1rem;
}
.talentText{
  padding: 0px 0px 0px 0px;
}
}

@media only screen and (device-width: 1024px) and (device-height: 600px) {
.MusicRow{
  padding-top: 2rem;
}
}