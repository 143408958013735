.check {
  /* position: absolute; */
  border-radius: 50%;
  width: 183px;
  height: 156px;
  background: linear-gradient(
    180deg,
    #243974 0%,
    rgba(255, 255, 255, 0.15) 100%
  );
}
.roadmapdiv{
  padding: 1rem 0rem 1rem 0rem;
}
.q42021 {
  /* position: absolute; */
  width: 183px;
  height: 109px;
  left: 693px;
  top: 130px;
}

.q42021ul {
  border: 1px solid rebeccapurple;
  background: linear-gradient(
    180deg,
    #243974 0%,
    rgba(255, 255, 255, 0.15) 100%
  );
  display: table-cell;
  margin: 19px;
  padding-top: 12px 0px 0px 0px;
}
/* abc {
  background: #3f51b5;
  color: #ffffff;
  padding: 8 px 20 px;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 20 px;
  margin-top: 10 px;
  display: inline-block;
  border-radius: 2 px;
  box-shadow: 0 1px 3px -1px rgb(0 0 0 / 60%);
}
 */
.roadmaprow {
  padding-top: 12px;
}
.r2021Q1,
.r2021Q4,
.r2021Q2,
.r2022Q3,
.r2022Q4,
.r2023Q1 {
  border-radius: 50%;
  padding: 30px !important;
  width: max-content !important;
  background: linear-gradient(
    180deg,
    #243974 0%,
    rgba(255, 255, 255, 0.15) 100%
  ) !important;
  font-size: 12px;
}
.r2021Q4 {
  margin-top: 23%;
  padding: 27px 31px 27px 31px !important;
}
.r2021Q1 {
  padding: 27px 55px 27px 55px !important;
  margin-top: 15%;
}
.r2021Q2 {
  margin-top: 25%;
  padding: 27px 65px 27px 65px !important;
}

.r2022Q3 {
  padding: 27px 55px 27px 55px !important;
}

.r2022Q4 {
  margin-left: 3rem;
  padding: 27px 55px 27px 55px !important;
}
.r2023Q1{
  margin-top: 4rem;
  padding: 25px 50px 0px 40px !important;
  height: 149px;
  margin-bottom: 1rem;

}


.r2021Q1 p,
.r2021Q2 p,
.r2021Q4 p,
.r2022Q3 p,
.r2022Q4 p,
.r2023Q1 {
  font-size: 14px;
}

.r2023Q11{
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1100px) and (min-width: 600px) {
  .r2021Q1 {
    margin-left: 7rem;
    margin-top: 1rem;
  }
  .r2022Q4 {
    margin-left: 15rem;
  }
  .r2023Q1{
    display: flex;
    justify-content: center;
  }
}

@media only screen and (device-width: 1280px) and (device-height: 800px) {
  .r2022Q4 {
    margin-left: 7rem;
    margin-top: 1rem;
  }
}
