.GamePolar {
  color: red;
}

.advGame{
  font-family: Poppins;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 6px;
  padding-bottom: 1rem;
}
.gameHead {
  padding-top: 8.2rem;
  padding-left: 1rem;
  font-family: "Black Ops One", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 4rem;
}
.gamingButtoni {
  background-color: yellow;
  padding: 12px 22px 12px 22px;
  border-radius: 12px;
  margin-left: 3rem;
}
.imminent {
  padding-top: 3rem;
  font-family: Poppins;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 6px;
}
.firstTopImg {
  position: relative;
  width: 350px;
  height: 350px ;
  border-radius: 12px;
  margin-bottom: 10rem;
}
.firstTopImg2 {
  position: absolute;
  left: 9rem;
  margin-top: 5.6rem;
  width: 350px;
  height: 350px ;
  border-radius: 12px;
}
.firstTopImg3 {
  position: absolute;
  left: 16rem;
  width: 350px;
  height: 350px ;
  margin-top: 10rem;
  border-radius: 12px;
}
.firstTopImg3:hover {
  position: absolute;
  left: 16rem;
  width: 350px;
  height: 350px !important;
  margin-top: 10rem;
  animation: shake 3s;
  animation-iteration-count: infinite;
}
.firstTopImg2:hover {
  position: absolute;
  left: 9rem;
  margin-top: 5.6rem;
  width: 350px;
  height: 350px !important;
  z-index: 12;
  animation: shake 3s;
  animation-iteration-count: infinite;
}
.firstTopImg:hover {
  position: relative;
  width: 350px;
  z-index: 12;
  height: 350px !important;
  animation: shake 3s;
  animation-iteration-count: infinite;
}

.zealGif img{
width: 290px;
}
.gamePreContainer{
  padding-top: 3rem;
}
.gamePrerequisitesg{
  font-family: 'Black Ops One';
  font-size: 3rem;
  padding: 0%;
  margin-bottom: 0 !important;
  align-self: flex-end;
}
.gamePrerequisites{
  font-family: 'Black Ops One';
  margin: 0 !important;
  font-size: 3rem;
  padding-left:8rem ;
}
.gameContainer2{
  padding: 4rem 6rem 4rem 6rem !important;
  line-height: 2rem;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.gameInfoText {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: justify;
}

/* 3rd section  */
.game3section {
  margin-top: 4rem;
  padding: 3rem 2rem 3rem 2rem;
  background-color: black !important;
}
.game3sectiontext {
  font-size: 6rem;
  padding-left: 4rem;
  text-transform: uppercase;
  text-shadow: 0 0 30px #ff0000, 0 0 208px #0000ff;
}
.gameTextinfdiv {
  padding-top: 2rem;
  border: 1px solid linear-gradient(to right, red, purple);
  margin-left: 6rem !important;
  border: 1px dotted white;
}

.gameTextinf {
  text-align: justify;
}
.game3sectiontext2 {
  font-size: 6rem;
  text-transform: uppercase;
  text-shadow: 0 0 30px #ff0000, 0 0 208px #0000ff;
}


@media only screen and (max-width: 600px) {
  .gameHead{
    font-size: 30px;
  }
  .firstTopImg,.firstTopImg2,.firstTopImg3{
    width: 150px;
    height: 150px;
  }
  .firstTopImg{
    left: 2rem;
  }
  .firstTopImg2{
    left: 5rem;
  }
  .firstTopImg3{
    left: 9rem;
  }

  .gameContent1{
    word-spacing: 9px;
  }
  .gamePrerequisitesg{
    font-size: 2rem;
  }
  .zealGif img{
    width: 200px;
  }
  .gamePrerequisites{
    font-size: 2rem;
    padding-left: 0rem;
  }
  .gameContainer2{
    text-align: justify;
    padding: 1rem 2rem 1rem 2rem !important;
  }
  .advGame{
    font-size: 2rem;
  }
  .imminent{
    font-size: 2rem;
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .firstTopImg,.firstTopImg2,.firstTopImg3{
    width: 190px;
    height: 190px;
  }
  .gameContainer2{
    padding: 1rem 1rem 1rem 1rem !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
.gameHead{
  padding-left: 9rem;
  font-size: 4rem;
}
.gamingButtoni{
  margin-left: 9rem;
}
.imminentDiv img {
  margin-left: 12rem;
  width: 700px;
}
.gameInfoText{
  font-size: 1.8rem;
}
.firstTopImg,.firstTopImg2,.firstTopImg3{
  width: 400px;

}
.firstTopImg2{
  left: 13rem;
}
.firstTopImg3{
  left: 25rem;
}
.firstTopImg{
  left: 4rem;
}
.gameContent1{
  font-size: 1.8rem;
}
.gamePrerequisites{
  text-align: center;
  padding-right:5rem !important ;
}
.gameContainer2 p{
  font-size: 1.8rem;
  padding: 1rem 6rem 1rem 4rem;
line-height: 2.9rem;
}
}